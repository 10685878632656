import i18next from 'i18next';

function* phase1(layers, key, prefix, moved, seen) {
    const layer = layers.get(key);
    for (const section of layer.schema.sections.filter(x => x.fragment)) {
        for (const obj of layer.categories[section.category]) {
            if (obj.fragment && obj.values) {
                const name = obj.values[section.fragment];
                if (name) {
                    const fragment = `${prefix}${name}`;

                    if (seen.has(fragment)) {
                        yield {
                            type: 'rename',
                            fragment: obj.fragment,
                            layer: obj.parent,
                            obj: obj.id,
                            error: "A page with the same name already exists",
                            deferred: null,
                        };
                    } else if (obj.fragment && seen.has(obj.fragment)) {
                        yield {
                            type: 'rename',
                            fragment: obj.fragment,
                            layer: obj.parent,
                            obj: obj.id,
                            error: "Do conflicting renames in two stages to prevent data loss",
                            deferred: null,
                        };
                    } else if (layers.has(obj.id)) {
                        seen.add(fragment)
                        if (obj.fragment && obj.fragment !== fragment) {
                            moved.add(fragment);
                            moved.add(obj.fragment);
                            moved.add(obj.id);
                            seen.add(obj.fragment)
                            // Moved page (handler should ignore failures if page doesn't exist)
                            yield {
                                type: 'rename',
                                fragment,
                                layer: obj.parent,
                                obj: obj.id,
                                error: null,
                                *deferred() {
                                    yield* phase1(layers, obj.id, `${fragment}/`);
                                },
                            };
                        } else {
                            yield* phase1(layers, obj.id, `${fragment}/`, moved, seen);
                        }
                    } else if (obj.fragment && obj.fragment !== fragment) {
                        moved.add(fragment);
                        moved.add(obj.fragment);
                        moved.add(obj.id);
                        seen.add(obj.fragment);
                        seen.add(fragment);
                        yield {
                            type: 'rename',
                            fragment,
                            layer: obj.parent,
                            obj: obj.id,
                            error: null,
                            *deferred() {
                                yield* phase1(layers, obj.id, `${fragment}/`);
                            },
                        };
                    }
                } else {
                    moved.add(obj.id);
                    yield {
                        type: 'rename',
                        fragment: obj.fragment,
                        layer: obj.parent,
                        obj: obj.id,
                        error: "No name has been set",
                        deferred: null,
                    };
                }
            }
        }
    }
}

function* phase2(layers, key, moved, ...parts) {
    const layer = layers.get(key);
    const fragment = parts.length ? parts.join('/') : null;
    if (layer.upstream == null) {
        yield {
            type: 'create',
            fragment,
            layer: key,
            obj: null,
            error: null,
            deferred: null,
        };
    } else if (layer.version !== layer.flushed) {
        yield {
            type: 'edit',
            fragment,
            layer: key,
            obj: null,
            error: null,
            deferred: null,
        };
    }

    let changed = false;
    for (const section of layer.schema.sections) {
        for (const obj of layer.categories[section.category]) {
            if (!changed) {
                for (const key of obj.links.keys()) {
                    changed |= moved.has(key);
                }
            }

            if (obj.values && section.fragment) {
                const name = obj.values[section.fragment];
                if (name) {
                    if (layers.has(obj.id)) yield* phase2(layers, obj.id, moved, ...parts, name);
                } else {
                    yield {
                        type: 'edit',
                        fragment: null,
                        layer: obj.parent,
                        obj: obj.id,
                        error: "No name has been set",
                        deferred: null,
                    };
                }
            }
        }
    }

    if (layer.categories['Reference']?.values) {
        for (const key of layer.categories['Reference'].links.keys()) {
            changed |= moved.has(key);
        }
    }
    if (layer.categories['Proxy']?.values) {
        for (const key of layer.categories['Proxy'].links.keys()) {
            changed |= moved.has(key);
        }
    }

    if (layer.upstream != null && layer.version === layer.flushed && changed) {
        yield {
            type: 'edit',
            fragment,
            layer: key,
            obj: null,
            error: null,
            deferred: null,
        };
    }
}

function* chunks(categories, schema, context) {
    for (const attr of schema.attributes) {
        if (attr.category) {
            const obj = categories[attr.category];
            if (obj?.values) yield {
                category: obj.category,
                digest: obj.upstream?.digest ?? null,
                group: obj.group || null,
                properties: Object.keys(obj.values).map(name => ({
                    name,
                    value: obj.values[name] ?? null,
                })),
            }
        }
        if (attr.settings) {
            yield* chunks(categories, attr.settings, context);
        }
    }
    for (const section of schema.sections) {
        for (const obj of categories[section.category].filter(x => x.values)) {
            if (obj.position) obj.values.location = `${obj.position.x}, ${obj.position.y}`;
            for (const dependency of section.dependencies) {
                obj.values[dependency] = context.label(obj.values[dependency]) || null;
            }
            if (section.incoming) {
                const values = []
                for (const item of obj.references) {
                    if (item[1]) {
                        values.push(context.label(item[0]))
                    }
                }
                obj.values[section.incoming] = values.sort().join(' ') || null;
            }
            if (section.outgoing) {
                const values = []
                for (const item of obj.references) {
                    if (!item[1]) {
                        values.push(context.label(item[0]))
                    }
                }
                obj.values[section.outgoing] = values.sort().join(' ') || null;
            }
            if (section.links && section.fragment) {
                const values = []
                for (const item of obj.links) {
                    if (item[1]) {
                        values.push(context.label(item[0]))
                    }
                }
                obj.values[section.links] = values.sort().join(' ') || null;
            } else if (section.links) {
                // NOTE: This case is actually singleton links
                const values = []
                let docid = '';
                for (const item of obj.links) {
                    if (item[1]) {
                        values.push(context.fqn(item[0]))
                        docid = context.docid(item[0])
                    }
                }
                obj.values[section.links] = values.sort().join(' ') || obj.values[section.links];
                obj.values.docid = docid
            }
            yield {
                category: obj.category,
                digest: obj.upstream?.digest ?? null,
                group: obj.group || null,
                properties: Object.keys(obj.values).map(name => ({
                    name,
                    value: obj.values[name] ?? null,
                })),
            }
        }
        if (section.settings) {
            yield* chunks(categories, section.settings, context);
        }
    }
    if (schema.settings) {
        yield* chunks(categories, schema.settings, context);
    }
}

function extractObjects(values, section) {
    switch (section.category) {
        case "NamedConstraint":
        case "FieldConstraint":
            if (values.constraints) return values.constraints[section.label];
            else if ('constraints' in values) return [];
            else return values[section.label] || [];
        default:
            return values[section.label] || [];
    }
}

function injectSingleton(layer, name, category, values, mappings) {
    const obj = {
        id: `${layer.prefix}${name}`,
        version: 0,
        parent: layer.id,
        section: layer.schema.attributes.filter(x => x.category === category),
        category: category,
        upstream: null,
        fragment: null,
        values,
        cached: {},
        group: null,
        position: null,
        references: new Map(),
        links: new Map(),
        readonly: true,
    };
    layer.categories[category] = obj;
    layer.children.set(obj.id, obj);
    mappings.set(obj.id, obj);
}

function injectLayer(parent, values, section, mappings) {
    const layer = {
        id: `${parent.prefix}${values[section.fragment]}`,
        parent: parent.id,
        prefix: `${parent.prefix}${values[section.fragment]}.`,
        upstream: null,
        categories: {},
        children: new Map(),
        version: 0,
        flushed: 0,
        pending: [],
        schema: SCHEMA[section.category],
        unknown: [],
        readonly: true,
    };
    switch (section.category) {
        case "Prototype":
        case "AggregateRoot":
            injectSingleton(layer, 'data_type', 'Reference', { name: values.data_type }, mappings);
            break;
        case "Query":
            injectSingleton(layer, 'return_type', 'Reference', { name: values.return_type }, mappings);
            break;
        case "Subscription":
            injectSingleton(layer, 'source', 'Reference', { name: values.source }, mappings);
            break;
        case "Process":
            injectSingleton(layer, 'state_type', 'Reference', values.state_type ? { name: values.state_type } : null, mappings);
            break;
        case "Subprocess":
            injectSingleton(layer, 'event_type', 'Reference', { name: values.event_type }, mappings);
            injectSingleton(layer, 'sink', 'Proxy', values.proxy ? { target: values.proxy.target, renamed: values.proxy.renamed.map(x => `${x.name}=${x.value}`).join(' ') } : null, mappings);
            break;
        case "Action":
            injectSingleton(layer, 'sink_type', 'Reference', values.inline ? { name: values.inline.sink } : null, mappings);
            injectSingleton(layer, 'proxy', 'Proxy', values.proxy ? { target: values.proxy.target, renamed: values.proxy.renamed.map(x => `${x.name}=${x.value}`).join(' ') } : null, mappings);
            break;
        case "Context":
        case "View":
            injectSingleton(layer, 'return_type', 'Reference', values.inline ? { name: values.inline.return_type } : null, mappings);
            injectSingleton(layer, 'proxy', 'Proxy', values.proxy ? { target: values.proxy.target, renamed: values.proxy.renamed.map(x => `${x.name}=${x.value}`).join(' ') } : null, mappings);
            break;
    }
    return layer;
}


const REGEX = /^([A-Za-z._0-9]+)(?:\(([A-Za-z_0-9/]+)\))?(.+?)?(OPTIONAL)?\s*(COLLECTION)?\s*$/;

function injectObject(layer, section, values, layout) {
    const obj = {
        id: null,
        version: 0,
        parent: layer.id,
        section: section,
        category: section.category,
        upstream: null,
        fragment: null,
        values: null,
        cached: {},
        group: null,
        position: null,
        references: new Map(),
        links: new Map(),
        readonly: true,
        errors: [],
    };
    switch (section.category) {
        case "Domain":
        case "Engine":
        case "Portal":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                realm: values.provider,
                relations: values.relations.join(" "),
            };
            break;
        case "Context":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                associations: values.associations.map(x => x.split('.', 2)[1]).join(" "),
            };
            break;
        case "Option":
        case "Property": {
                obj.id = `${layer.prefix}${values.name}`;
                obj.values = values;
                const match = REGEX.exec(values.data_type);
                if (!match) obj.values = {
                    ...values,
                    data_type: null,
                    cardinality : values.data_type.includes('OPTIONAL') ? '0' : (values.data_type.includes('COLLECTION') ? null : '1'),
                    metadata: values.data_type,
                    special: null,
                };
                else  obj.values = {
                    ...values,
                    data_type: match[1],
                    cardinality: match[4] ? '0' : (match[5] ? null : '1'),
                    metadata: match[3],
                    special: match[2],
                };
            }
            break;
        case "Process":
        case "UserInput":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                transitions: values.transitions.map(x => x.split('.', 2)[1]).join(" "),
                policy_groups: values.policy_groups.join(" "),
            };
            break;
        case "Subprocess":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                transitions: values.transitions.map(x => x.split('.', 2)[1]).join(" "),
            };
            break;
        case "Interrupt":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                user_inputs: values.user_inputs.map(x => x.split('.', 2)[1]).join(" "),
                emits: values.event_type.split('.', 2)[1],
            };
            break;
        case "PolicyGroup":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                source: values.source ? values.source.split('.', 2)[1] : null,
                metadata: values.general_access ? "ga" : null,
            };
            break;
        case "Command":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                emits: values.event_type.split('.', 2)[1],
                policy_groups: values.policy_groups.join(" "),
            };
            break;
        case "Query":
        case "AggregateRoot":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                policy_groups: values.policy_groups.join(" "),
            };
            break;
        case "Subscription":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                emits: values.event_type.split('.', 2)[1],
            };
            break;
        case "Table":
            obj.id = `${layer.prefix}${values.field}`;
            obj.values = values;
            break;
        case "Entity":
        case "Index":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = {
                ...values,
                fields: values.fields.join(','),
            };
            break;
        case "Configuration":
        case "DataType":
        case "Prototype":
        case "Event":
        case "FieldConstraint":
        case "NamedConstraint":
        case "Lookup":
        case "Regex":
        case "Provider":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = values;
            break;
        case "Action":
        case "View":
        case "Component":
            obj.id = `${layer.prefix}${values.name}`;
            obj.values = values.inline ? {
                ...values,
                ...values.inline,
                policy_groups: values.inline.policy_groups.join(' '),
            } : {
                ...values,
                ...values.proxy,
            };
            break;
        case "Dependency":
        case "Navigation":
        case "Workflow":
            obj.id = `${layer.prefix}${layer.categories[section.category].length}`;
            obj.values = values;
            break;
    }
    obj.position = layout.get(obj.id) ?? null;
    return obj;
}

function linkLayer(categories, mappings, schema, prefix) {
    for (const section of schema.sections) {
        if (section.incoming) {
            for (const obj of categories[section.category].filter(x => x.values)) {
                for (const key of (obj.values[section.incoming] ?? '').split(' ').map(x => x.trim()).filter(x => x)) {
                    const target = mappings.get(`${prefix}${key}`);
                    if (target?.values) {
                        obj.references.set(target.id, true);
                        target.references.set(obj.id, false);
                    }
                }
            }
        }
        if (section.outgoing) {
            for (const obj of categories[section.category].filter(x => x.values)) {
                for (const key of (obj.values[section.outgoing] ?? '').split(' ').map(x => x.trim()).filter(x => x)) {
                    const target = mappings.get(`${prefix}${key}`);
                    if (target?.values) {
                        obj.references.set(target.id, false);
                        target.references.set(obj.id, true);
                    }
                }
            }
        }
        if (section.links) {
            const prepend = section.fragment ? prefix : '';
            for (const obj of categories[section.category].filter(x => x.values)) {
                for (const key of (obj.values[section.links] ?? '').split(' ').map(x => x.trim()).filter(x => x)) {
                    const target = mappings.get(`${prepend}${key}`);
                    if (target?.values) {
                        obj.links.set(target.id, true);
                    }
                }
            }
        }
    }
    if (categories['Reference']?.values) {
        const target = mappings.get(categories['Reference'].values.name);
        if (target?.values) {
            categories['Reference'].links.set(target.id, false);
        }
    }
    if (categories['Proxy']?.values) {
        const target = mappings.get(categories['Proxy'].values.target);
        if (target?.values) {
            categories['Proxy'].links.set(target.id, false);
        }
    }
}

function mergeObject(context, layer, pair, section, local, remote) {
    const values = {};
    const name = pair.action == 'remote' || (pair.action == 'automerge' && pair.local == null) ? 'remote' : 'local';
    for (const value of pair.values) {
        values[value.key] = value[name];
    }
    const upstream = pair.remote != null ? remote[pair.remote] : null;
    const obj = pair.local != null ? local[pair.local] : {
        id: `${context.i++}`,
        version: 0,
        parent: layer.id,
        section: section,
        category: upstream.category,
        upstream,
        fragment: section.fragment && values[section.fragment] ? `${layer.prefix}${values[section.fragment]}` : null,
        values,
        cached: {},
        group: upstream.group,
        position: null,
        references: new Map(),
        links: new Map(),
        readonly: layer.readonly,
        errors: [],
    };
    obj.upstream = upstream;
    if (!context.has(obj.id)) {
        context.mappings.set(obj.id, obj);
        layer.children.set(obj.id, obj);
    }
    if (pair.remote == null && pair.action == 'remote') {
        obj.cached = values;
        obj.values = null;
    } else {
        obj.cached = obj.values ?? {};
        obj.values = values;
        obj.references.clear();
        obj.links.clear();
    }
    return obj;
}

export function createContext(bus, title, readonly) {
    return {
        bus,
        title,
        readonly,
        i: 0,
        layers: new Map(),
        mappings: new Map(),
        errors: new Map(),
        primitives: [...PRIMITIVES.entries()].map(x => ({ value: x[0], description: x[1] })),
        docid(key) {
            if (!this.layers.has(key)) return '';
            const obj = this.layers.get(key);
            if (obj.upstream) return obj.upstream.docid;
            else return '';
        },
        fqn(key) {
            if (!this.mappings.has(key)) return key;
            const obj = this.mappings.get(key);
            const name = obj.values?.name ?? obj.cached?.name;
            if (obj.parent == null) {
                return name;
            } else {
                const parent = this.mappings.get(obj.parent);
                return `${parent.values?.name ?? parent.cached?.name}/${name}`;
            }
        },
        fqp(key) {
            if (!this.mappings.has(key)) return key;
            const obj = this.mappings.get(key);
            const name = obj.values?.name ?? obj.cached?.name;
            if (obj.parent == null) {
                return name;
            } else {
                const parent = this.mappings.get(obj.parent);
                return `${parent.values?.name ?? parent.cached?.name}.${name}`;
            }
        },
        /*
         * Phase 1: Apply moves from bottom up (redirects preserve the relationship)
         * Phase 2: Edit created/modified pages from top down (relative references preserve the relationship, capture absolute references)
         * Phase 3: Insert captured absolute references (handled by the caller)
         *
         * Note: deletions are explicitly ignored. They are deferred until a name conflict occurs, and handled with the usual conflict resolution tools.
         */
        *phase1(moved) {
            yield* phase1(this.layers, null, '', moved, new Set());
        },
        *phase2(moved) {
            yield* phase2(this.layers, null, moved);
        },
        lookups() {
            return this.layers.get(null).categories["Lookup"].filter(x => x.values);
        },
        regexes() {
            return this.layers.get(null).categories["RegularExpression"];
        },
        attribute(key, category) {
            const layer = this.layers.get(key);
            return layer.categories[category]?.id ?? null;
        },
        targets(key) {
            const selected = [];
            const obj = this.mappings.get(key);
            const layer = this.layers.get(null);
            for (const section of layer.schema.sections.filter(x => x.category in SCHEMA)) {
                if (SCHEMA[section.category].sections.findIndex(x => x.category === obj.category) > -1) {
                    for (const child of layer.categories[section.category]) {
                        if (this.layers.has(child.id)) selected.push(child.id);
                    }
                }
            }
            return selected;
        },
        prototypes(key, target) {
            const data = this.mappings.get(target);
            const parent = this.mappings.get(data.parent);
            const targets = new Set([data.id, target, `${parent.values.name}.${data.values.name}`]);
            let item = this.mappings.get(key);
            do {
                const layer = this.layers.get(item.parent);
                if ('Prototype' in layer.categories) return layer.categories['Prototype'].filter(x => {
                    // TODO: move prototype reference to realm level?
                    const prototype = this.layers.get(x.id);
                    return !prototype || targets.has(prototype.categories['Reference']?.values?.name);
                });
                else item = this.mappings.get(layer.id);
            } while (item);
            return [];
        },
        objects(key) {
            const selected = [];
            if (this.layers.has(key)) {
                const item = this.mappings.get(key);
                const layer = this.layers.get(key);
                const schema = SCHEMA[item?.category ?? null];
                for (const section of schema.sections) {
                    selected.push(...layer.categories[section.category]);
                }
            }
            return selected;
        },
        *links(key) {
            if (this.layers.has(key)) {
                const layer = this.layers.get(key);
                for (const category in layer.categories) {
                    switch (category) {
                        case 'Reference':
                        case 'Proxy':
                        case 'Document':
                            if (layer.categories[category]?.values)
                                yield* layer.categories[category].links;
                            break;
                        default:
                            for (const obj of layer.categories[category]) {
                                if (obj.values) yield* obj.links;
                            }
                    }
                }
            }
        },
        has(key) {
            return this.mappings.has(key);
        },
        get(key) {
            return this.mappings.get(key);
        },
        output(key) {
            if (key != null && this.layers.has(key)) {
                const item = this.mappings.get(key);
                const layer = this.layers.get(key);
                if ('Proxy' in layer.categories && item.values?.description == null) {
                    const child = layer.categories['Proxy'];
                    return this.output(child.values?.target);
                } else if ('Reference' in layer.categories) {
                    const child = layer.categories['Reference'];
                    return child?.values?.name;
                }
            }
            return null;
        },
        fields(key) {
            if (key != null && this.mappings.has(key)) {
                const item = this.mappings.get(key);
                if (item.category === 'Reference') {
                    return this.fields(item.values.name);
                } else if (item.category === 'Proxy') {
                    return this.fields(item.values.target);
                } else if (this.layers.has(item.id)) {
                    const layer = this.layers.get(item.id);
                    if (item.category === 'Configuration') {
                        return layer.categories['Option'].filter(x => x.values?.name).map(option => ({
                            name: option.values.name,
                            data_type: option.values.data_type ?? '',
                            cardinality: option.values.cardinality ?? '1',
                            schema: [],
                        }));
                    } else if ('Property' in layer.categories){
                        return layer.categories['Property'].filter(x => x.values?.name).map(property => ({
                            name: property.values.name,
                            data_type: property.values.data_type ?? '',
                            cardinality: property.values.cardinality,
                            schema: this.fields(property.values.data_type),
                        }));
                    } else {
                        return [];
                    }
                } else return [];
            } else return [];
        },
        label(key) {
            const obj = this.mappings.get(key);
            if (!obj?.values) {
                return key ? key.toLowerCase() : key;
            }

            if (!obj.values.name) {
                return obj.category ? `new ${i18next.t(`lbl.modal.${obj.section.label}`).toLowerCase()}` : i18next.t('lbl.modal.null');
            } else if (obj.label === 'lookups') {
                return `lookup(${obj.values.name})`;
            } else if (obj.label === 'regexes') {
                return `regex(${obj.values.name})`;
            } else return obj.values.name;
        },
        category(key) {
            const obj = this.mappings.get(key);
            if (!obj) {
                return key ? key.toLowerCase() : key;
            } else {
                return i18next.t(`lbl.modal.${obj.section.label}`);
            }
        },
        policy_groups(key) {
            const groups = new Map();
            const item = this.mappings.get(key);
            if (item.values.policy_groups) {
                for (const path of item.values.policy_groups.split(' ')) {
                    const val = path.trim();
                    if (val) groups.set(val, val);
                }
            }
            const parent = this.mappings.get(item.parent);
            const target = this.mappings.get(parent.values.realm);
            const provider = target && this.layers.get(target.id);
            if (provider) {
                for (const entry of provider.children.entries()) {
                    if (entry[1] === 'PolicyGroup') {
                        const fqn = `${target.values.name}/${this.mappings.get(entry[0]).values.name}`;
                        groups.set(fqn, fqn);
                    }
                }
            }
            return [...groups.entries()].sort();
        },
        isPrimitive(value) {
            if (PRIMITIVES.has(value)) {
                return true;
            }
            var obj = this.mappings.get(value);
            return !obj || (obj.label === 'regexes' || obj.label === 'lookups');
        },
        merge(layer, steps, unknown, upstream) {
            const categories = {};
            for (const { attribute, section, pairs, local, remote } of steps) {
                if (attribute && pairs.length) {
                    categories[attribute.category] = mergeObject(this, layer, pairs[0], attribute, local, remote);
                } else if (attribute) {
                    categories[attribute.category] = null;
                } else {
                    categories[section.category] = pairs.map(pair => mergeObject(this, layer, pair, section, local, remote));
                }
            }
            layer.categories = categories;
            layer.unknown = unknown;
            layer.upstream = upstream;
            linkLayer(categories, this.mappings, layer.schema, layer.prefix);
        },
        create(parent, section, group, position, values) {
            const layer = this.layers.get(parent);
            const links = new Map();
            if (values && section.links) {
                const prepend = section.fragment ? layer.prefix : '';
                for (const key of (values[section.links] ?? '').split(' ').map(x => x.trim()).filter(x => x)) {
                    const target = this.mappings.get(`${prepend}${key}`);
                    if (target?.values) {
                        links.set(target.id, true);
                    }
                }
            } else if (values && section.category == 'Reference' && values.name) {
                const target = this.mappings.get(values.name);
                if (target) links.set(target.id, false);
            } else if (values && section.category == 'Proxy' && values.target) {
                const target = this.mappings.get(values.target);
                if (target) links.set(target.id, false);
            }

            const item = {
                id: `${this.i++}`,
                version: 0,
                parent,
                section,
                category: section.category,
                upstream: null,
                fragment: null,
                values: values ?? {},
                cached: {},
                group: group ?? null,
                position: position ?? null,
                references: new Map(),
                links,
                readonly: this.readonly,
                errors: [],
            };
            const prior = layer.categories[item.category];
            return {
                bus: this.bus,
                mappings: this.mappings,
                prior,
                section: Array.isArray(prior) ? [...prior, item] : item,
                layer,
                item,
                undo() {
                    this.mappings.delete(this.item.id);
                    this.layer.categories[this.item.category] = this.prior;
                    this.layer.version--;
                    this.bus.$emit('deleted', this.item);
                    return { selected: null, model: false };
                },
                redo() {
                    this.mappings.set(this.item.id, this.item);
                    this.layer.categories[this.item.category] = this.section;
                    this.layer.version++;
                    this.bus.$emit('created', this.item);
                    return { selected: this.item, model: false };
                },
            }
        },
        paste(parent, section, values) {
            const layer = this.layers.get(parent);
            const items = values.map(x => ({
                id: `${this.i++}`,
                version: 0,
                parent,
                section,
                category: section.category,
                upstream: null,
                fragment: null,
                values: x,
                cached: {},
                group: null,
                position: null,
                references: new Map(),
                links: new Map(),
                readonly: this.readonly,
                errors: [],
            }));
            const prior = layer.categories[section.category];
            return {
                bus: this.bus,
                mappings: this.mappings,
                category: section.category,
                prior,
                layer,
                items,
                undo() {
                    for (const item of this.items) {
                        this.mappings.delete(item.id);
                    }
                    this.layer.categories[this.category] = this.prior;
                    this.layer.version--;
                    return { selected: null, model: false };
                },
                redo() {
                    for (const item of this.items) {
                        this.mappings.set(item.id, item);
                    }
                    this.layer.categories[this.category] = [...this.layer.categories[this.category], ...this.items];
                    this.layer.version++;
                    return { selected: null, model: false };
                },
            }
        },
        center(id) {
            const position = this.mappings.get(id)?.position;
            this.bus.$emit('center', position);
        },
        update(id, field, value, link) {
            const item = this.mappings.get(id);
            return {
                bus: this.bus,
                layer: this.layers.get(item.parent),
                item,
                field,
                value,
                prior: item.values[field] ?? null,
                cached: item.cached[field],
                links: item.links,
                linked: link ? new Map(this.mappings.has(value) ? [ [
                    this.mappings.get(value).id,
                    item.category !== 'Reference' && item.category !== 'Proxy'
                ] ] : []) : item.links,
                undo() {
                    this.item.cached[this.field] = this.cached;
                    this.item.values[this.field] = this.prior;
                    this.item.links = this.links;
                    this.item.version--;
                    this.layer.version--;
                    this.bus.$emit('updated', this.item);
                    return { selected: this.item, model: false };
                },
                redo() {
                    this.item.cached[this.field] = this.prior;
                    this.item.values[this.field] = this.value;
                    this.item.links = this.linked;
                    this.item.version++;
                    this.layer.version++;
                    this.bus.$emit('updated', this.item);
                    return { selected: this.item, model: false };
                },
            }
        },
        migrate(parent, captured, target) {
            const src = this.layers.get(parent);
            const dst = this.layers.get(target);
            const left = {...src.categories};
            const right = {...dst.categories};
            const items = [];
            for (const id of captured) {
                const item = this.mappings.get(id);
                const i = left[item.category].indexOf(item);
                left[item.category] = [
                    ...left[item.category].slice(0, i),
                    ...left[item.category].slice(i+1),
                ];
                right[item.category] = [...right[item.category], item];
                items.push(item);
            }
            return {
                bus: this.bus,
                items,
                src: {
                    layer: src,
                    cached: src.categories,
                    updated: left,
                },
                dst: {
                    layer: dst,
                    cached: dst.categories,
                    updated: right,
                },
                undo() {
                    this.src.layer.categories = this.src.cached;
                    this.dst.layer.categories = this.dst.cached;
                    this.src.layer.version--;
                    this.dst.layer.version--;
                    this.items.forEach(x => {
                        x.parent = this.src.layer.id;
                        x.version--;
                        this.bus.$emit('created', x);
                    });
                    return { selected: this.item, model: true };
                },
                redo() {
                    this.src.layer.categories = this.src.updated;
                    this.dst.layer.categories = this.dst.updated;
                    this.src.layer.version++;
                    this.dst.layer.version++;
                    this.items.forEach(x => {
                        x.parent = this.dst.layer.id;
                        x.version++;
                        this.bus.$emit('deleted', x);
                    });
                    return { selected: null, model: true };
                },
            }
        },
        move(id, x, y, group) {
            const item = this.mappings.get(id);
            return {
                bus: this.bus,
                layer: this.layers.get(item.parent),
                item,
                original: item.group,
                updated: group,
                src: item.position,
                dst: { x, y },
                undo() {
                    this.item.group = this.original;
                    this.item.position = this.src;
                    this.item.version--;
                    this.layer.version--;
                    this.bus.$emit('moved', this.item);
                    return { selected: this.item, model: true };
                },
                redo() {
                    this.item.group = this.updated;
                    this.item.position = this.dst;
                    this.item.version++;
                    this.layer.version++;
                    this.bus.$emit('moved', this.item);
                    return { selected: this.item, model: true };
                },
            }
        },
        multimove(positions) {
            const items = positions.map(({ id }) => this.mappings.get(id));
            return {
                bus: this.bus,
                items,
                layers: this.layers,
                src: new Map(items.map(({id, position}) => [id, position])),
                dst: new Map(positions.map(({id, x, y}) => [id, {x ,y}])),
                undo() {
                    for (const item of this.items) {
                        item.position = this.src.get(item.id);
                        item.version--;
                        this.layers.get(item.parent).version--;
                    }
                    this.bus.$emit('multimoved', {positions: this.items});
                    return { selected: null, model: true };
                },
                redo() {
                    for (const item of this.items) {
                        item.position = this.dst.get(item.id);
                        item.version++;
                        this.layers.get(item.parent).version++;
                    }
                    this.bus.$emit('multimoved', {positions: this.items});
                    return { selected: null, model: true };
                },
            }
        },
        group(captured, name) {
            const items = captured.map(id => this.mappings.get(id));
            return {
                bus: this.bus,
                items,
                layers: this.layers,
                src: new Map(items.map(({id, group}) => [id, group])),
                name,
                undo() {
                    for (const item of this.items) {
                        item.group = this.src.get(item.id);
                        item.version--;
                        this.layers.get(item.parent).version--;
                    }
                    this.bus.$emit('multimoved', {positions: this.items});
                    return { selected: null, model: true };
                },
                redo() {
                    for (const item of this.items) {
                        item.group = this.name;
                        item.version++;
                        this.layers.get(item.parent).version++;
                    }
                    this.bus.$emit('multimoved', {positions: this.items});
                    return { selected: null, model: true };
                },
            }
        },
        shift(parent, category, src, dst) {
            const layer = this.layers.get(parent);
            const prior = layer.categories[category];
            const updated = src < dst ? [
                ...prior.slice(0, src),
                ...prior.slice(src+1, dst+1),
                prior[src],
                ...prior.slice(dst+1),
            ] : [
                ...prior.slice(0, dst),
                prior[src],
                ...prior.slice(dst, src),
                ...prior.slice(src+1),
            ];
            return {
                bus: this.bus,
                layer,
                prior,
                updated,
                category,
                undo() {
                    this.layer.categories[this.category] = this.prior;
                    this.layer.version--;
                    return { selected: null, model: false };
                },
                redo() {
                    this.layer.categories[this.category] = this.updated;
                    this.layer.version++;
                    return { selected: null, model: false };
                },
            }
        },
        link(id, src, dst, reset) {
            const selected = this.mappings.get(id);
            const left = this.mappings.get(src);
            const right = this.mappings.get(dst);
            const link = left.section.links && left.section.links === right.section.label;
            const affected = link ? left.links : left.references;
            return {
                bus: this.bus,
                layer: this.layers.get(selected.parent),
                selected,
                src: left,
                dst: right,
                link,
                affected: reset ? [...affected].filter(x => !x[1]).map(x => this.mappings.get(x[0])) : [],
                undo() {
                    if (this.link) {
                        for (const obj of this.affected) {
                            this.src.links.set(obj.id, true);
                        }
                        if (this.src.links.has(this.dst.id)) {
                            this.src.links.delete(this.dst.id);
                        } else {
                            this.src.links.set(this.dst.id, true);
                        }
                    } else {
                        for (const obj of this.affected) {
                            this.src.references.set(obj.id, false);
                            obj.references.set(this.src.id, true);
                        }
                        if (this.src.references.has(this.dst.id)) {
                            this.src.references.delete(this.dst.id);
                            this.dst.references.delete(this.src.id);
                        } else {
                            this.src.references.set(this.dst.id, false);
                            this.dst.references.set(this.src.id, true);
                        }
                    }
                    this.src.version--;
                    this.dst.version--;
                    this.layer.version--;
                    this.bus.$emit('updated', this.src);
                    return { selected: this.selected, model: true };
                },
                redo() {
                    if (this.link) {
                        for (const obj of this.affected) {
                            this.src.links.delete(obj.id);
                        }
                        if (this.src.links.has(this.dst.id)) {
                            this.src.links.delete(this.dst.id);
                        } else {
                            this.src.links.set(this.dst.id, true);
                        }
                    } else {
                        for (const obj of this.affected) {
                            this.src.references.delete(obj.id);
                            obj.references.delete(this.src.id);
                        }
                        if (this.src.references.has(this.dst.id)) {
                            this.src.references.delete(this.dst.id);
                            this.dst.references.delete(this.src.id);
                        } else {
                            this.src.references.set(this.dst.id, false);
                            this.dst.references.set(this.src.id, true);
                        }
                    }
                    this.src.version++;
                    this.dst.version++;
                    this.layer.version++;
                    this.bus.$emit('updated', this.src);
                    return { selected: this.selected, model: true };
                },
            }
        },
        delete(id) {
            const item = this.mappings.get(id);
            const layer = this.layers.get(item.parent);
            return {
                bus: this.bus,
                mappings: this.mappings,
                layer,
                item,
                cached: item.cached,
                undo() {
                    this.item.values = this.item.values ?? this.item.cached;
                    this.item.cached = this.cached;
                    this.item.references.forEach((value, key) => {
                        const target = this.mappings.get(key);
                        target.references.set(this.item.id, !value);
                        target.version--;
                    });
                    this.item.version--;
                    this.layer.version--;
                    this.bus.$emit('created', this.item);
                    return { selected: this.item, model: false };
                },
                redo() {
                    this.item.cached = this.item.values ?? this.item.cached;
                    this.item.values = null;
                    this.item.references.forEach((_, key) => {
                        const target = this.mappings.get(key);
                        target.references.delete(this.item.id);
                        target.version++;
                    });
                    this.item.version++;
                    this.layer.version++;
                    this.bus.$emit('deleted', this.item);
                    return { selected: null, model: false };
                },
            }
        },
        restore(id, field, value) {
            const item = this.mappings.get(id);
            const layer = this.layers.get(item.parent);
            return {
                bus: this.bus,
                mappings: this.mappings,
                layer,
                item,
                target: field ? {
                    field,
                    prior: item.values[field],
                    value: item.cached[field] ?? value,
                } : null,
                cached: item.cached,
                undo() {
                    if (this.target) {
                        this.item.values[this.target.field] = this.target.prior;
                        this.item.version--;
                        this.layer.version--;
                        this.bus.$emit('updated', this.item);
                        return { selected: this.item, model: false };
                    } else {
                        this.item.cached = this.item.values ?? this.item.cached;
                        this.item.values = null;
                        this.item.references.forEach((_, key) => {
                            const target = this.mappings.get(key);
                            target.references.delete(this.item.id);
                            target.version--;
                        });
                        this.item.version--;
                        this.layer.version--;
                        this.bus.$emit('deleted', this.item);
                        return { selected: null, model: false };
                    }
                },
                redo() {
                    if (this.target) {
                        this.item.values[this.target.field] = this.target.value;
                        this.item.version++;
                        this.layer.version++;
                        this.bus.$emit('updated', this.item);
                    } else {
                        this.item.values = this.item.values ?? this.item.cached;
                        this.item.cached = {};
                        this.item.references.forEach((value, key) => {
                            const target = this.mappings.get(key);
                            target.references.set(this.item.id, !value);
                            target.version++;
                        });
                        this.item.version++;
                        this.layer.version++;
                        this.bus.$emit('created', this.item);
                    }
                    return { selected: this.item, model: false };
                },
            };
        },
        layer(upstream, parent, id, category, prefix) {
            // TODO: error handling
            const categories = {};
            const children = new Map();
            const sections = new Map();
            const schema = SCHEMA[category];
            const pending = [];
            const unknown = [];
            inject(schema, sections, categories, true);
            let errors = upstream && this.errors.has(upstream.docid);
            const chunks = errors ? this.errors.get(upstream.docid).chunks : null;
            for (const item of upstream?.chunks ?? []) {
                if (sections.has(item.category)) {
                    const section = sections.get(item.category);
                    const values = {};
                    for (const { name, value } of item.properties) {
                        if (!section.properties || !(section.properties[name] ?? false)) {
                            values[name] = value;
                        } else {
                            values[name] = (value ?? '').split(' ').map(x => x.trim()).filter(x => x);
                        }
                    }
                    const match = LOCATION.exec(values.location ?? '');
                    const obj = {
                        id: `${this.i++}`,
                        version: 0,
                        parent: id,
                        section: section,
                        category: item.category,
                        upstream: item,
                        fragment: null,
                        values,
                        cached: {},
                        group: item.group,
                        position: match ? { x: parseInt(match[1], 10), y: parseInt(match[2], 10) } : null,
                        references: new Map(),
                        links: new Map(),
                        readonly: this.readonly,
                        errors: item.errors,
                    }
                    errors |= !!item.errors.length;
                    if (section.fragment && values[section.fragment]) {
                        obj.fragment = `${prefix}${values[section.fragment]}`;
                        pending.push(obj.id);
                        this.mappings.set(obj.fragment, obj);
                        if (this.errors.has(obj.fragment)) {
                            const failed = this.errors.get(obj.fragment);
                            obj.errors.push(...failed.local);
                            if (!obj.errors.length) obj.errors.push({
                                title: 'Invalid data',
                                detail: 'Check the model for errors',
                            });
                            errors = true;
                        }
                    } else if (!section.fragment && chunks?.has(item.digest)) {
                        obj.errors.push(...chunks.get(item.digest));
                        errors = true;
                    }
                    if (Array.isArray(categories[item.category])) {
                        categories[item.category].push(obj);
                    } else if (!categories[item.category]) {
                        categories[item.category] = obj;
                    }
                    this.mappings.set(obj.id, obj);
                    children.set(obj.id, item.category);
                } else unknown.push(item);
            }
            linkLayer(categories, this.mappings, schema, prefix);
            const layer = {
                id,
                parent,
                prefix,
                upstream,
                categories,
                children,
                version: 0,
                flushed: 0,
                pending,
                schema,
                unknown,
                readonly: this.readonly,
                hasError: false,
            };
            if (errors) {
                let current = layer;
                do {
                    current.hasError = true;
                    current = this.layers.get(current.parent);
                } while (current?.hasError === false);
            }
            this.layers.set(id, layer);
            this.bus.$emit('load', id);
            return layer;
        },
        view(id, schema) {
            if (id == null) {
                return {
                    key: "$module",
                    page: null,
                    item: null,
                    title: this.title,
                    subtitle: null,
                    schema: SCHEMA[null],
                    error: TERMINAL,
                    readonly: this.readonly,
                };
            }

            const item = this.mappings.get(id);
            if (schema) {
                return {
                    key: `${item.id}$settings`,
                    page: item.parent,
                    item,
                    title: item.values.name ?? item.category,
                    subtitle: "settings",
                    schema,
                    error: TERMINAL,
                    readonly: item.readonly,
                };
            } else {
                return {
                    key: `${item.id}`,
                    page: item.id,
                    item,
                    title: item.values.name ?? item.category,
                    subtitle: item.category,
                    schema: SCHEMA[item.category],
                    error: TERMINAL,
                    readonly: item.readonly,
                };
            }
        },
        decorate(model, errors) {
            this.errors.clear();
            for (const error of errors) {
                if (error.fragment) {
                    let fragment = error.fragment;
                    while (!this.errors.has(fragment)) {
                        this.errors.set(fragment, {
                            local: [],
                            chunks: new Map(),
                        });
                        let i = fragment.lastIndexOf('/');
                        if (i > 0) fragment = fragment.slice(0, i);
                    }
                }

                const node = this.errors.get(error.docid) ?? this.errors.get(error.fragment) ?? {
                    local: [],
                    chunks: new Map(),
                };
                if (error.chunk) {
                    const children = node.chunks.get(error.chunk) ?? [];
                    children.push(error);
                    node.chunks.set(error.chunk, children);
                } else {
                    node.local.push(error);
                }
                this.errors.set(error.docid, node);
            }
        },
        *inject(...artifacts) {
            const layer = this.layers.has(null) ? this.layers.get(null) : this.layer(null, null, null, null, '');
            yield [null, layer];
            for (const model of artifacts) {
                const layout = new Map(model.locations.map(x => [x.name, { x: x.x, y: x.y }]));
                const stack = [{
                    layer,
                    obj: model,
                    pending: [...layer.schema.sections],
                }];
                while (stack.length) {
                    const context = stack.pop();
                    while (context.pending.length) {
                        const section = context.pending.pop();
                        if (!(section.category in context.layer.categories)) {
                            context.layer.categories[section.category] = [];
                        }
                        if (section.settings) context.pending.push(...section.settings.sections);
                        for (const obj of extractObjects(context.obj, section)) {
                            const child = injectObject(context.layer, section, obj, layout);
                            context.layer.children.set(child.id, child);
                            context.layer.categories[section.category].push(child);
                            this.mappings.set(child.id, child);
                            if (section.fragment) {
                                const nested = injectLayer(context.layer, child.values, section, this.mappings);
                                this.layers.set(nested.id, nested);
                                yield [nested.id, nested];
                                const pending = [...nested.schema.sections];
                                if (nested.schema.attributes) pending.push(...nested.schema.attributes.filter(x => x.settings).flatMap(x => x.settings.sections));
                                if (nested.schema.settings) pending.push(...nested.schema.settings.sections);
                                stack.push({
                                    layer: nested,
                                    obj,
                                    pending,
                                });
                            }
                        }
                    }
                    linkLayer(context.layer.categories, this.mappings, context.layer.schema, context.layer.prefix);
                }
            }
        },
        *flush(key) {
            const layer = this.layers.get(key);
            if (layer.categories['Reference']?.values) {
                for (const item of layer.categories['Reference'].links) {
                    if (!item[1]) {
                        layer.categories['Reference'].values.name = this.fqn(item[0]) ?? layer.categories['Reference'].values.name;
                        layer.categories['Reference'].values.docid = this.docid(item[0]);
                    }
                }
            }
            if (layer.categories['Proxy']?.values) {
                for (const item of layer.categories['Proxy'].links) {
                    if (!item[1]) {
                        layer.categories['Proxy'].values.target = this.fqn(item[0]) ?? layer.categories['Proxy'].values.target;
                        layer.categories['Proxy'].values.docid = this.docid(item[0]);
                    }
                }
            }
            yield* chunks(layer.categories, layer.schema, this);
            yield* layer.unknown;
        },
    };
}

const TERMINAL = {
    field: () => null,
    get: () => TERMINAL,
    local: () => null,
}

function inject(schema, sections, categories) {
    for (const attr of schema.attributes) {
        if (attr.category) {
            categories[attr.category] = null;
            sections.set(attr.category, attr);
        }
        if (attr.settings) inject(attr.settings, sections, categories);
    }
    for (const section of schema.sections) {
        categories[section.category] = [];
        sections.set(section.category, section);
        if (section.settings) inject(section.settings, sections, categories);
    }
    if (schema.settings) {
        inject(schema.settings, sections, categories);
    }
}

const LOCATION = /^\s*([+-]?[0-9]+)\s*,\s*([+-]?[0-9]+)\s*$/;

const PRIMITIVES = new Map([
    ['ATTACHMENT', 'attachment'],
    ['BOOLEAN', 'boolean'],
    ['BYTES', 'bytes'],
    ['DATETIME', 'datetime'],
    ['DECIMAL', 'decimal'],
    ['FLOAT', 'float'],
    ['INTEGER', 'integer'],
    ['STRING', 'string'],
    ['UUID', 'uuid'],
    ['system.execution_error', 'error'],
]);

const CONFIGURATION = {
    label: "configuration",
    category: "Configuration",
    settings: null,
    incoming: null,
    outgoing: null,
    links: null,
    fragment: "name",
    dependencies: [],
    properties: null,
};

const DATA_TYPE = {
    label: "data_types",
    category: "DataType",
    settings: null,
    incoming: null,
    outgoing: null,
    links: null,
    fragment: "name",
    dependencies: [],
    properties: null,
};

const PROTOTYPE = {
    label: "prototypes",
    category: "Prototype",
    settings: null,
    incoming: null,
    outgoing: null,
    links: 'data_type',
    fragment: "name",
    dependencies: [],
    properties: null,
};

const EVENT = {
    label: "events",
    category: "Event",
    settings: null,
    incoming: null,
    outgoing: null,
    links: null,
    fragment: "name",
    dependencies: [],
    properties: null,
};

const SUBSCRIPTION = {
    label: "subscriptions",
    category: "Subscription",
    settings: null,
    incoming: "source",
    outgoing: "emits",
    links: null,
    fragment: "name",
    dependencies: [],
    properties: null,
};

const FIELD = {
    label: "fields",
    category: "FieldConstraint",
    settings: null,
    incoming: null,
    outgoing: null,
    links: null,
    fragment: null,
    dependencies: [],
    properties: null,
};

const NAMED = {
    label: "named",
    category: "NamedConstraint",
    settings: null,
    incoming: null,
    outgoing: null,
    links: null,
    fragment: null,
    dependencies: [],
    properties: null,
};

const SCHEMA = {
    null: {
        metrics: true,
        search: true,
        proxy: null,
        attributes: [],
        sections: [{
            label: "domains",
            category: "Domain",
            settings: null,
            incoming: "relations",
            outgoing: null,
            links: null,
            fragment: "name",
            dependencies: ["realm"],
            properties: {
                realm: false,
            },
        }, {
            label: "engines",
            category: "Engine",
            settings: null,
            incoming: "relations",
            outgoing: null,
            links: null,
            fragment: "name",
            dependencies: ["realm"],
            properties: {
                realm: false,
            },
        }, {
            label: "portals",
            category: "Portal",
            settings: null,
            incoming: "relations",
            outgoing: null,
            links: null,
            fragment: "name",
            dependencies: ["realm"],
            properties: {
                realm: false,
            },
        }, {
            label: "lookups",
            category: "Lookup",
            settings: null,
            incoming: null,
            outgoing: null,
            links: null,
            fragment: null,
            dependencies: ["realm"],
            properties: {
                realm: false,
            },
        }, {
            label: "regexes",
            category: "Regex",
            settings: null,
            incoming: null,
            outgoing: null,
            links: null,
            fragment: null,
            dependencies: [],
            properties: null,
        }, {
            label: "providers",
            category: "Provider",
            settings: null,
            incoming: null,
            outgoing: null,
            links: null,
            fragment: "name",
            dependencies: [],
            properties: null,
        }],
        settings: null,
    },
    "Domain": {
        metrics: true,
        search: true,
        proxy: null,
        attributes: [],
        sections: [
            CONFIGURATION,
            DATA_TYPE,
            PROTOTYPE,
            EVENT,
            SUBSCRIPTION,
            {
                label: "commands",
                category: "Command",
                settings: null,
                incoming: null,
                outgoing: "emits",
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "queries",
                category: "Query",
                settings: null,
                incoming: null,
                outgoing: null,
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "aggregate_roots",
                category: "AggregateRoot",
                settings: null,
                incoming: null,
                outgoing: null,
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            }
        ],
        settings: null,
    },
    "Engine": {
        metrics: true,
        search: true,
        proxy: null,
        attributes: [],
        sections: [
            CONFIGURATION,
            DATA_TYPE,
            PROTOTYPE,
            EVENT,
            {
                label: "processes",
                category: "Process",
                settings: null,
                incoming: null,
                outgoing: "transitions",
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "user_inputs",
                category: "UserInput",
                settings: null,
                incoming: null,
                outgoing: "transitions",
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "interrupts",
                category: "Interrupt",
                settings: null,
                incoming: null,
                outgoing: "emits",
                links: 'user_inputs',
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "subprocesses",
                category: "Subprocess",
                settings: null,
                incoming: null,
                outgoing: "transitions",
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
        ],
        settings: null,
    },
    "Portal": {
        metrics: true,
        search: true,
        proxy: null,
        attributes: [],
        sections: [
            CONFIGURATION,
            DATA_TYPE,
            PROTOTYPE,
            EVENT,
            SUBSCRIPTION,
            {
                label: "contexts",
                category: "Context",
                settings: null,
                incoming: "associations",
                outgoing: null,
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "actions",
                category: "Action",
                settings: null,
                incoming: "source",
                outgoing: null,
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "views",
                category: "View",
                settings: null,
                incoming: "source",
                outgoing: null,
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
            {
                label: "components",
                category: "Component",
                settings: null,
                incoming: "source",
                outgoing: null,
                links: null,
                fragment: "name",
                dependencies: [],
                properties: null,
            },
        ],
        settings: null,
    },
    "Provider": {
        search: true,
        proxy: null,
        attributes: [],
        sections: [
            {
                label: "policy_groups",
                category: "PolicyGroup",
                settings: null,
                incoming: "source",
                outgoing: null,
                links: null,
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: null,
    },
    "Configuration": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "options",
                category: "Option",
                settings: null,
                incoming: null,
                outgoing: null,
                links: null,
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "DataType": {
        search: false,
        proxy: null,
        attributes: [],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: null,
    },
    "Prototype": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "data_type",
            category: "Reference",
            settings: {
                search: true,
                proxy: null,
                attributes: [],
                sections: [
                    FIELD,
                    NAMED,
                ],
            },
            properties: {
                name: false,
            },
        }],
        sections: [],
        settings: null,
    },
    "Event": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [{
                label: "audit",
                category: null,
                settings: null,
                properties: null,
            }],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "Subscription": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "source",
            category: "Reference",
            settings: {
                search: true,
                proxy: null,
                attributes: [],
                sections: [
                    FIELD,
                    NAMED,
                ],
                settings: null,
            },
            properties: {
                name: false,
            },
        }, {
            label: "emits",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [],
        settings: null,
    },
    "Command": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "emits",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "Query": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "return_type",
            category: "Reference",
            settings: null,
            properties: null,
        }, {
            label: "page_size",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "AggregateRoot": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "data_type",
            category: "Reference",
            settings: {
                search: true,
                proxy: null,
                attributes: [{
                    label: "summary",
                    category: null,
                    properties: null,
                }, {
                    label: "search",
                    category: null,
                    properties: null,
                }, {
                    label: "document",
                    category: "Document",
                    properties: null,
                }],
                sections: [
                    FIELD,
                    NAMED,
                    {
                        label: "indexes",
                        category: "Index",
                        settings: null,
                        incoming: null,
                        outgoing: null,
                        fragment: null,
                        dependencies: [],
                        properties: null,
                    },
                    {
                        label: "entities",
                        category: "Entity",
                        incoming: null,
                        outgoing: null,
                        fragment: null,
                        dependencies: [],
                        properties: null,
                    },
                    {
                        label: "tables",
                        category: "Table",
                        incoming: null,
                        outgoing: null,
                        fragment: null,
                        dependencies: [],
                        properties: null,
                    },
                ],
            },
            properties: null,
        }, {
            label: "key_type",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [],
        settings: null,
    },
    "Process": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "state_type",
            category: "Reference",
            settings: null,
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "Subprocess": {
        search: false,
        proxy: null,
        attributes: [{
            label: "sink",
            category: "Proxy",
            settings: null,
            properties: null,
        }, {
            label: "event_type",
            category: "Reference",
            settings: null,
            properties: null,
        }],
        sections: [],
        settings: null,
    },
    "Interrupt": {
        search: false,
        proxy: null,
        attributes: [{
            label: "emits",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [],
        settings: null,
    },
    "UserInput": {
        search: false,
        proxy: null,
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "Action": {
        search: false,
        proxy: {
            sink: false,
            policy_groups: false,
            properties: false,
            dependencies: false,
            target: true,
        },
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "sink",
            category: "Reference",
            settings: null,
            properties: null,
        }, {
            label: "target",
            category: "Proxy",
            settings: {
                search: false,
                proxy: null,
                attributes: [{
                    label: "renamed",
                    category: null,
                    settings: null,
                    properties: null,
                }],
                sections: [],
                settings: null,
            },
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "dependencies",
                category: "Dependency",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'service',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "navigations",
                category: "Navigation",
                settings: {
                    search: false,
                    proxy: null,
                    attributes: [{
                        label: "src",
                        category: null,
                        settings: null,
                        properties: null,
                    }, {
                        label: "context",
                        category: null,
                        settings: null,
                        properties: null,
                    }, {
                        label: "body",
                        category: null,
                        settings: null,
                        properties: null,
                    }],
                    sections: [],
                    settings: null,
                },
                incoming: null,
                outgoing: null,
                links: 'dst',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "workflows",
                category: "Workflow",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'service',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "Context": {
        search: false,
        proxy: {
            view: false,
            return_type: false,
            policy_groups: false,
            properties: false,
            dependencies: false,
            target: true,
        },
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "view",
            category: null,
            properties: null,
        }, {
            label: "return_type",
            category: "Reference",
            properties: null,
        }, {
            label: "target",
            category: "Proxy",
            settings: {
                search: false,
                proxy: null,
                attributes: [{
                    label: "renamed",
                    category: null,
                    settings: null,
                    properties: null,
                }],
                sections: [],
                settings: null,
            },
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "dependencies",
                category: "Dependency",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'service',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "navigations",
                category: "Navigation",
                settings: {
                    search: false,
                    proxy: null,
                    attributes: [{
                        label: "src",
                        category: null,
                        settings: null,
                        properties: null,
                    }, {
                        label: "context",
                        category: null,
                        settings: null,
                        properties: null,
                    }, {
                        label: "body",
                        category: null,
                        settings: null,
                        properties: null,
                    }],
                    sections: [],
                    settings: null,
                },
                incoming: null,
                outgoing: null,
                links: 'dst',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "workflows",
                category: "Workflow",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'service',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
    "View": {
        search: false,
        proxy: {
            page_size: false,
            return_type: false,
            escalate: false,
            policy_groups: false,
            properties: false,
            dependencies: false,
            target: true,
        },
        attributes: [{
            label: "metadata",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "page_size",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "cache_seconds",
            category: null,
            settings: null,
            properties: null,
        }, {
            label: "return_type",
            category: "Reference",
            settings: null,
            properties: null,
        }, {
            label: "target",
            category: "Proxy",
            settings: {
                search: false,
                proxy: null,
                attributes: [{
                    label: "renamed",
                    category: null,
                    settings: null,
                    properties: null,
                }],
                sections: [],
                settings: null,
            },
            properties: null,
        }, {
            label: "policy_groups",
            category: null,
            settings: null,
            properties: null,
        }],
        sections: [
            {
                label: "properties",
                category: "Property",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'data_type',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "dependencies",
                category: "Dependency",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'service',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "navigations",
                category: "Navigation",
                settings: {
                    search: true,
                    proxy: null,
                    attributes: [{
                        label: "src",
                        category: null,
                        settings: null,
                        properties: null,
                    }, {
                        label: "context",
                        category: null,
                        settings: null,
                        properties: null,
                    }, {
                        label: "body",
                        category: null,
                        settings: null,
                        properties: null,
                    }],
                    sections: [],
                    settings: null,
                },
                incoming: null,
                outgoing: null,
                links: 'dst',
                fragment: null,
                dependencies: [],
                properties: null,
            },
            {
                label: "workflows",
                category: "Workflow",
                settings: null,
                incoming: null,
                outgoing: null,
                links: 'service',
                fragment: null,
                dependencies: [],
                properties: null,
            },
        ],
        settings: {
            search: true,
            proxy: null,
            attributes: [],
            sections: [
                FIELD,
                NAMED,
            ],
            settings: null,
        },
    },
}